import React, { useEffect, useState, useCallback, useRef } from 'react';
import { FormGroup, CardHeader, Input, Button, Row, Col, Collapse, Card, CardBody, Nav, NavItem, Dropdown, DropdownItem, DropdownToggle, DropdownMenu, CardFooter, Modal, UncontrolledTooltip, UncontrolledDropdown } from 'reactstrap';
import { COLORS } from '../../utils/color';
import Create_card, { ButtonsArray, KelvinColorPicker, ParametricDimensionInput } from '../Create_card';
import FocusWithin from '../FocusWithin';
import Icons from '../Icons';
import { Switch, Dropdown as AntdDropdown, Radio } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAlert } from 'react-alert';
import repopulate_panel_pop_up from '../../utils/panel_pop_up_repopulation';
import repopulate_panel from '../../utils/panel_repopulation';
import { send_analytics } from '../../utils/send_analytics';
import sleep from '../../utils/sleep';
import { handleError } from '../../utils/errors';
import MaterialDropdown, { MaterialDropdownNew } from '../MaterialDropdown';
import { clone_active_modal_onclick, fetch_store_materials_onclick, on_click_picker, open_material_settings, reset_material } from '../../utils/material_utils';
import { CompositeDropdownPanel } from '../CompositeDropdown';
import HatchingDropdown from '../HatchingDropdown';
import IImg from '../IImg';

const styles_panel = {
    text_elipsis: {

    },
    input_styles: {

    },
    input_styles_small: {

    },
    collapse: {

    },
};

const showHeader = (is_grouped, configuration_type) => {
    return !(is_grouped && configuration_type != 'group_by_2' && configuration_type != 'names_above')
}

const is_collapsable = (json_item) => {
    return json_item.name && json_item.component_type === "section"
}

const getPanelItemWidthCustom = (width, item_count) => {
    return `calc( ${width} - ( ${item_count - 1} * var(--panel_section_item_gap_x) / ${item_count} ) )`
}

const getPanelItemWidth = (parent_item, item, metric_system) => {

    if (parent_item.configuration_type == 'group_by_2' && ((item.type == 'dimension_input' && !item.expression_available_parameters) || item.type == 'text_input' || item.type == 'toggle' || item.type == 'select_input' || item.type == 'colorpicker')) {
        return 'calc( 50% - ( var(--panel_section_item_gap_x) / 2 ) )'
    } else if (parent_item.group_child_inputs && ((item.type == 'dimension_input' && (metric_system == 'mm' || item.is_unitless_true) && !item.expression_available_parameters) || item.type == 'text_input' || item.type == 'colorpicker' || item.type == 'select_input')) {
        return 'calc( 33.3% - ( 2 * var(--panel_section_item_gap_x) / 3 ) )'
    }
    return '100%'
}


// ------------------ Generic Parent Components Start ----------------------------

export const PanelSection = ({ on_click, common_onchange_handler, parent, parent_menu, panel_key, json_item, set_panel_has_unapplied_edits, update_view, metric_system, approximation_mm, parent_id, approximation_ft, set_page_loader, handle_ui_response, approxMM, mmToFeetParts, panel_explode, is_grouped, index, configuration_type, set_modal_mode, set_layerset_mode, set_given_layerset, inside_tab, is_panel_pop_up, inside_tab_toolbar, active_tab_toolbar, mode, quick_dropdown_open, panel_search_json, is_search_mode, set_is_search_mode, search_string_panel, set_search_string_panel, panel_search_string, show_search_everything, is_draggable, onSubmitCallback }) => {
    const alert = useAlert()
    const [re_render, set_re_render] = useState(true);
    const forceReRender = () => { set_re_render(!re_render) }

    const icon_on_click_inner_fn = async ({ route, form, update_panel }) => {
        try {
            common_onchange_handler();
            if (window.debug_mode) {
                window.add_debug_log(route + "(\"" + JSON.stringify(form).replace(/"/g, '\\"') + "\");");
            }
            let promise_resp = window.Module[route](JSON.stringify(form));
            var ui_response = await window.Promisify(promise_resp);

            window.handle_ui_response(ui_response);
            // repopulate_panel_pop_up();
            if (update_panel)
                is_panel_pop_up ? repopulate_panel_pop_up() : repopulate_panel();
            // mode == 'search' ? populate_panel_search(search_string_panel) :''

            window.update_view();
        } catch (err) {
            if (err.constructor.name.includes("Error")) {
                err = new Error(err);
            }
            err.name = "Captured error in Panel menu onclick error at " + route + ", form=" + JSON.stringify(form) + " : " + err.name
            console.error(err);
            window.sentry_capture_exception("", err);
            alert.error("Something went wrong")
            set_page_loader({
                show: false,
                text: 'Please wait'
            });
            return;

        }
    }

    const icon_on_click = async ({ route, form, item, update_panel = true }) => {
        if (item && item.call_with_loader) {
            set_page_loader({ show: true, text: "Please Wait..." }, async () => {
                await sleep(10)
                await icon_on_click_inner_fn({ route, form, update_panel })
                set_page_loader({ show: false, text: "Please Wait..." })
            })
        } else {
            await icon_on_click_inner_fn({ route, form, update_panel })
        }

        try {

            const top_level_component = window.get_panel_top_level_element()
            if (top_level_component) {
                let analytics_data = {}
                analytics_data.category = `${top_level_component} edited`;
                analytics_data.option = `${window.get_current_panel_level()}.${(parent ? (parent.name + '.') : '') + route}`
                send_analytics(analytics_data, true)

            }
        } catch (err) {
            console.error("Could not send analytics_data for left panel property edited")
            handleError(err)
        }
    }

    const dropdown_on_click = async () => {
        var active_design = window.Module.get_active_design()
        var active_view = active_design.active_view;
        if (mode) {
            if (window.debug_mode) {
                window.add_debug_log("active_design->active_view->toggle_dropdown_as_open_or_close(\"" + ("section" + json_item.id + mode) + "\");");
            }
            active_view.toggle_dropdown_as_open_or_close("section" + json_item.id + mode)
        }
        else {
            if (window.debug_mode) {
                window.add_debug_log("active_design->active_view->toggle_dropdown_as_open_or_close(\"" + ("section" + json_item.id) + "\");");
            }
            active_view.toggle_dropdown_as_open_or_close("section" + json_item.id)
        }
        forceReRender()
        active_design.delete();
        active_view.delete();
    }

    const is_dropdown_open = () => {
        if (window.Module) {
            if (mode) {
                return !window.Module.get_dropdown_open_close_state("section" + json_item.id + mode);
            }
            else {
                return !window.Module.get_dropdown_open_close_state("section" + json_item.id);
            }
        }

        return false;
    }

    useEffect(() => {
        if (window.Module.is_current_panel_fresh() && is_collapsable(json_item) && is_dropdown_open() && json_item.open_configuration && json_item.open_configuration[mode ? mode : "normal"] === false) {
            dropdown_on_click()
        }
    }, []);

    const SectionMenu = () => (
        <Collapse isOpen={true} style={json_item.disabled ? { width: '100%', overflowX: 'hidden', opacity: 0.5, pointerEvents: 'none' } : { width: '100%', overflowX: 'hidden', opacity: 1 }}>
            <PanelSectionMenu component_type={json_item.component_type}>
                {
                    json_item.submenu && json_item.submenu.length ? json_item.submenu.map((sub_item, idx) => (
                        <div key={json_item.id + idx} className='panel_section_menu_item' style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', width: getPanelItemWidth(json_item, sub_item, metric_system), overflowX: 'hidden' }}>
                            <Create_card
                                is_section={true}
                                panel_key={panel_key}
                                set_page_loader={set_page_loader}
                                handle_ui_response={handle_ui_response}
                                key={json_item.id + idx}
                                metric_system={metric_system}
                                approximation_mm={approximation_mm}
                                approximation_ft={approximation_ft}
                                parent={json_item}
                                parent_id={json_item.id}
                                parent_menu={json_item.submenu}
                                update_view={update_view}
                                json_item={sub_item}
                                set_panel_has_unapplied_edits={set_panel_has_unapplied_edits}
                                approxMM={approxMM}
                                mmToFeetParts={mmToFeetParts}
                                panel_explode={panel_explode}
                                is_grouped={json_item.group_child_inputs}
                                index={idx}
                                configuration_type={json_item.configuration_type}
                                inside_tab={inside_tab}
                                is_panel_pop_up={is_panel_pop_up}
                                mode={mode}
                                panel_search_json={panel_search_json} is_search_mode={is_search_mode} set_is_search_mode={set_is_search_mode} search_string_panel={search_string_panel} set_search_string_panel={set_search_string_panel} panel_search_string={panel_search_string} onSubmitCallback={onSubmitCallback}
                            />
                        </div>
                    )) : ''
                }
            </PanelSectionMenu>
        </Collapse>
    )

    const renderPanelSectionMenu = () => {
        if (json_item.section_type === "fonts_section" || json_item.sub_section_type === "fonts_section") {
            return <TextPropertySection json_item={json_item} on_click={on_click} common_onchange_handler={common_onchange_handler} is_grouped={is_grouped} />
        }

        if (json_item.section_type === "insets_section" || json_item.sub_section_type === "insets_section") {
            return <DimensionInsetSection json_item={json_item} on_click={on_click} common_onchange_handler={common_onchange_handler} is_grouped={true} configuration_type={"names_above"} metric_system={metric_system} approxMM={approxMM} approximation_ft={approximation_ft} mmToFeetParts={mmToFeetParts} />
        }

        return (
            <Collapse isOpen={is_collapsable(json_item) ? is_dropdown_open() : true} style={json_item.disabled ? { width: '100%', overflowX: 'hidden', opacity: 0.5, pointerEvents: 'none' } : { width: '100%', overflowX: 'hidden', opacity: 1 }}>
                <PanelSectionMenu component_type={json_item.component_type}>
                    {
                        json_item.submenu && json_item.submenu.length ? json_item.submenu.map((sub_item, idx) => (
                            <div key={json_item.id + idx} className='panel_section_menu_item' style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', width: getPanelItemWidth(json_item, sub_item, metric_system), overflowX: 'hidden' }}>
                                <Create_card
                                    is_section={true}
                                    panel_key={panel_key}
                                    set_page_loader={set_page_loader}
                                    handle_ui_response={handle_ui_response}
                                    key={json_item.id + idx}
                                    metric_system={metric_system}
                                    approximation_mm={approximation_mm}
                                    approximation_ft={approximation_ft}
                                    parent={json_item}
                                    parent_id={json_item.id}
                                    parent_menu={json_item.submenu}
                                    update_view={update_view}
                                    json_item={sub_item}
                                    set_panel_has_unapplied_edits={set_panel_has_unapplied_edits}
                                    approxMM={approxMM}
                                    mmToFeetParts={mmToFeetParts}
                                    panel_explode={panel_explode}
                                    is_grouped={json_item.group_child_inputs}
                                    index={idx}
                                    configuration_type={json_item.configuration_type}
                                    inside_tab={inside_tab}
                                    is_panel_pop_up={is_panel_pop_up}
                                    mode={mode}
                                    panel_search_json={panel_search_json} is_search_mode={is_search_mode} set_is_search_mode={set_is_search_mode} search_string_panel={search_string_panel} set_search_string_panel={set_search_string_panel} panel_search_string={panel_search_string} onSubmitCallback={onSubmitCallback}
                                />
                            </div>
                        )) : ''
                    }
                </PanelSectionMenu>
            </Collapse>
        )
    }

    return (
        <div style={{ width: '100%', overflow: 'hidden' }} className={`${json_item.component_type === "section" ? "panel_section" : json_item.component_type === "sub_section" ? "panel_sub_section" : ""} ${json_item.disabled ? 'panel_disabled' : ''}`}>
            {
                json_item && (json_item.name || (json_item.buttons && json_item.buttons.length)) ?
                    <div onClick={is_collapsable(json_item) ? dropdown_on_click : null} className={`${is_dropdown_open() ? "" : "collapsed"} ${json_item.component_type === "section" ? "panel_section_header" : json_item.component_type === "sub_section" ? "panel_sub_section_header" : ""}`}>
                        <div title={json_item.name} className={`lines1_elipsis ${json_item.component_type === "section" ? "panel_section_heading_text" : json_item.component_type === "sub_section" ? "panel_sub_section_heading_text" : ""}`} >{json_item && json_item.name ? json_item.name + ' ' : ''}</div>
                        {
                            <ButtonsArray button_list={json_item.buttons} icon_on_click={icon_on_click} dropdown_open={is_dropdown_open()} />
                        }
                    </div>
                    : ''
            }
            <div className={`panel_menu_container ${json_item.disable_menu ? 'panel_disabled' : ''}`}>
                {renderPanelSectionMenu()}
            </div>

        </div>
    )
}

// ------------------ Generic Parent Components End ------------------------------


// ------------------ Generic Leaf Components Start ----------------------------

export const ListItemNew = ({ json_item, common_onchange_handler, on_click, is_grouped, icon_on_click }) => {
    const { t } = useTranslation()

    return (
        <PanelCard json_item={json_item} onClick={on_click} className={`flex_property panel_list_item_new ${json_item.color === "#FF5247" ? 'danger' : ''}`} style={{ color: json_item.color && json_item.color != "#FF5247" ? json_item.color : '', cursor: 'pointer' }}>
            {
                json_item.full_image_url ?
                    (
                        <img width='100%' style={{ maxHeight: '120px', objectFit: 'contain' }} title={json_item.name} src={json_item.full_image_url} />
                    )
                    : ''
            }
            <div className='flex_property inf-gap-2' style={{ width: '100%' }}>
                {
                    json_item.is_material ?
                        ((!json_item.icon && !json_item.icon_color) || json_item.mixed) ? (
                            <div className='flex_center' style={{ width: '24px', height: '24px', backgroundColor: '#E6E9EE', border: '1px dashed #A7A8B2', color: '#A7A8B2' }}>+</div>
                        ) : json_item.icon ? (
                            <img height='24px' width='24px' className='inf-border' title={json_item.name} src={json_item.icon} />
                        ) : json_item.icon_color ? (
                            <div className='inf-border' style={{ height: '24px', width: '24px', backgroundColor: json_item.icon_color }} title={json_item.name} />
                        ) : ''
                        :
                        json_item.icon ? <Icons name={json_item.icon} /> : ''
                }

                <div style={{ flex: 1, minWidth: '0px', fontWeight: json_item.route ? 500 : 700 }} className={'lines1_elipsis'} id={json_item.id} title={json_item.mixed ? "Mixed" : json_item.name}>{json_item.mixed ? "Mixed" : json_item.name}</div>
                
                {
                    json_item.is_material ? <div style={{ color: '#0078ff' }}>{t("Change")}</div> : ''
                }
                {
                    json_item.buttons && json_item.buttons.length ? <ButtonsArray button_list={json_item.buttons} icon_on_click={icon_on_click} /> : '' 
                }
            </div>
        </PanelCard>
    )
}

export const HatchingSelectnNew = ({ json_item, is_panel_pop_up }) => {
    return (
        <PanelCard json_item={json_item}>
            <PanelItemHeader json_item={json_item} getName={() => json_item && json_item.name ? json_item.name : 'Select a type of hatch'} configuration_type={"names_above"} />
            <HatchingDropdown composites={json_item.categories} json_item={json_item} is_panel_pop_up={is_panel_pop_up} />
        </PanelCard>
    )
}

export const CompositeSelectNew = ({ json_item, is_panel_pop_up, update_view }) => {
    return (
        <PanelCard json_item={json_item}>
            <CompositeDropdownPanel composites={json_item.composites} json_item={json_item} is_panel_pop_up={is_panel_pop_up} update_view={update_view} />
        </PanelCard>
    )
}

export const SelectAndDimensionInputNew = ({ json_item, common_onchange_handler, on_click, metric_system, approxMM, mmToFeetParts, approximation_ft }) => {
    return (
        <PanelCard json_item={json_item}>
            {
                json_item.full_select ?
                    <div className='flex_property'>
                        <div title={json_item.name_select} className='lines1_elipsis flex-1-mw'>{json_item.name_select}</div>
                        <div title={json_item.name_dim} className='lines1_elipsis flex-1-mw'>{json_item.name_dim}</div>
                    </div>
                    : ''
            }
            <div className='flex_property'>
                <div className='flex-1-mw'>
                    <div className='createcard_input_container_new input_small'>
                        <div title={json_item.name_select} className='lines1_elipsis flex-1-mw'>{json_item.name_select}</div>
                        <Input onChange={json_item.apply_on_select ? () => { common_onchange_handler(); on_click() } : () => { common_onchange_handler() }} id={json_item.id_select + 'st'} type="select" defaultValue={json_item.default_value_select}>
                            {json_item.options_select.map((o, idx) => (
                                <option key={idx} value={o.value}>{o.name}</option>
                            ))}
                        </Input>
                    </div>
                </div>
                <div className='flex-1-mw'>
                    {metric_system == 'mm' ? (
                        <div className='createcard_input_container_new input_small'>
                            <div title={json_item.name_dim} className='lines1_elipsis'>{json_item.name_dim}</div>
                            <Input type="text" onChange={common_onchange_handler} onBlur={on_click} onKeyDown={(e) => { if (e.key === 'Enter') { e.target.blur() } else if (e.key == 'Escape') { e.target.value = approxMM(json_item.default_value_dim) || 0; e.target.blur() } }} defaultValue={approxMM(json_item.default_value_dim) || 0} key={"key_" + json_item.id_dim} id={json_item.id_dim} style={{ ...styles_panel.input_styles, border: 'none', flex: 5, height: '24px' }} placeholder="0" />
                        </div>

                    ) : (
                        <DimensionInputFtIn json_item={json_item} defaultValueKey='default_value_dim' idKey='id_dim' getName={(json_item) => json_item.name_dim} is_grouped={true} configuration_type={json_item.full_select ? "names_above" : ""} on_click={on_click} common_onchange_handler={common_onchange_handler} mmToFeetParts={mmToFeetParts} approximation_ft={approximation_ft} />
                    )}
                </div>

            </div>
        </PanelCard>
    )
}

const ReferenceLevellDropdownNew = ({ json_item, on_click }) => {
    return (
        <AntdDropdown trigger={['click']} placement='bottomRight' menu={{
            onClick: ({ key }) => { console.log(key, "args"); on_click(key) },
            items: [
                ...(
                    json_item && json_item.ref_level_options ?
                        [...json_item.ref_level_options.map((o, idx) => {
                            return {
                                key: o.value,
                                label: <div className='lines1_elipsis' title={json_item.relative_ref_level && o.value != "no_ref_level" ? 'Up to ' + o.name : o.name}>{json_item.relative_ref_level && o.value != "no_ref_level" ? 'Up to ' + o.name : o.name}</div>
                            }
                        }),
                        { label: "Value" + (json_item.relative_ref_level ? " (Fixed)" : (json_item.global_ref_level ? " (From Global Origin)" : " (From Floor Base Top)")), key: "no_ref_level" }
                        ] : []
                )
            ]
        }}>
            <Icons name={"chevron_left"} className="inf-px-2 cp" style={{ transform: 'rotate(270deg', fontSize: '8px' }} />
        </AntdDropdown>
    );
}

export const RefLevelDimensionInputNew = ({ json_item, common_onchange_handler, metric_system, approximation_ft, mmToFeetParts, approxMM, on_click }) => {
    const [ref_level_value, set_ref_level_value] = useState("");
    const [trigger_on_click, set_trigger_on_click] = useState({ value: false });


    const get_active_ref_level_name = () => {
        if ((!ref_level_value) || ref_level_value == "no_ref_level") {
            return ((json_item.relative_ref_level ? ["Value", "Amount"] : (json_item.global_ref_level ? ["From", "Global Origin"] : ["From", "Floor Base Top"])));
            // return ("Value" + (json_item.relative_ref_level?" (Amount)":(json_item.global_ref_level?" (From Global Origin)":" (From Floor Base Top)")));
        } else if (json_item.ref_level_options && json_item.ref_level_options.find(o => o.value == ref_level_value)) {
            var name_find = json_item.ref_level_options.find(o => o.value == ref_level_value).name;
            // var name_find = json_item.ref_level_options.find(o=>o.value == ref_level_value).name + " with offset ";
            return json_item.relative_ref_level ? ["Up to ", name_find] : ["From ", name_find];
        } else {
            return ['', 'Reference Level Deleted'];
        }
    }

    const dropdown_on_click = (val) => {
        common_onchange_handler()
        set_ref_level_value(val);
        trigger_on_click.value = true
        // on_click()
    }

    useEffect(() => {
        if (ref_level_value && !(ref_level_value == json_item.default_ref_level)) {
            if (trigger_on_click.value) {
                on_click()
                trigger_on_click.value = false
            }
        }
    }, [ref_level_value]);

    useEffect(() => {
        set_ref_level_value(json_item.default_ref_level);
    }, [json_item.default_ref_level]);

    return (
        <PanelCard json_item={json_item}>
            <Input type="text" value={ref_level_value} style={{ display: "none" }} key={"key_" + json_item.id + "_ref_level_select"} id={json_item.id + "_ref_level_select"} readOnly />
            <div className='panel_flex_property_wrap'>
                <PanelItemHeader json_item={json_item} configuration_type={"names_above"} />
                <div className='flex_property' style={{ width: '100%', gap: 'var(--panel_section_item_gap_x)' }}>
                    <div className='flex-1-mw'>
                        <div className='createcard_input_container_new input_small'>
                            <div>{get_active_ref_level_name()[0]}</div>
                            <div style={{ textAlign: 'end' }} className='lines1_elipsis flex-1-mw' title={json_item.mixed ? 'Mixed' : get_active_ref_level_name()[1]}>{json_item.mixed ? 'Mixed' : get_active_ref_level_name()[1]}</div>
                            <ReferenceLevellDropdownNew json_item={json_item} on_click={dropdown_on_click} />
                        </div>
                    </div>
                    <div className='flex-1-mw'>
                        {metric_system == 'mm' ? (
                            <React.Fragment>
                                {((!ref_level_value) || ref_level_value == "no_ref_level") ? (
                                    // <Col className='class_position_unset' sm={6} style={{ fontSize: 12, display: "flex", alignItems: "center", justifyContent: 'center', width: '100%', paddingRight: 8, paddingLeft: '8px' }}>
                                    <div className='createcard_input_container_new input_small'>
                                        <PanelInput type="text" id={json_item.id} key={"key_" + json_item.id} onChange={common_onchange_handler} onBlur={json_item.onchange_route ? () => on_click() : null} defaultValue={json_item.mixed ? '' : (approxMM(json_item.default_value) || 0)} placeholder={json_item.mixed ? 'Mixed' : "0"} />
                                    </div>
                                    // </Col>
                                ) : (
                                    // <Col className='class_position_unset' sm={6} style={{ fontSize: 12, display: "flex", alignItems: "center", justifyContent: 'center', width: '100%', paddingRight: 8, paddingLeft: '8px' }}>
                                    <div className='createcard_input_container_new input_small'>
                                        <PanelInput type="text" id={json_item.id + "_offset"} key={"key_" + json_item.id + "_offset"} onChange={common_onchange_handler} onBlur={json_item.onchange_route ? () => on_click() : null} defaultValue={json_item.mixed ? '' : (approxMM(json_item.default_ref_level_offset) || 0)} placeholder={json_item.mixed ? 'Mixed' : "0"} />
                                    </div>
                                    // </Col>
                                )}
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                {((!ref_level_value) || ref_level_value == "no_ref_level") ? (
                                    <DimensionInputFtIn json_item={json_item} on_click={on_click} common_onchange_handler={common_onchange_handler} mmToFeetParts={mmToFeetParts} approximation_ft={approximation_ft} />

                                ) : (
                                    // <Col xs={6} style={{ padding: '0px 8px 0px 0px' }}>
                                    //     <FocusWithin className='createcard_input_container blue_highlight_focuswithin' onBlur={json_item.onchange_route ? on_click : null} style={{ display: 'flex', width: '100%', border: '1px solid ' + COLORS.panel_item_borders }}>
                                    //         <Col className='flex_property class_position_unset zero_padding' style={{ borderRight: '1px solid ' + COLORS.panel_item_borders, width: '40%' }}>
                                    //             <Input type="text" onChange={common_onchange_handler} onKeyDown={(e) => { if (e.key === 'Enter') { e.target.blur() } else if (e.key == 'Escape') { e.target.value = mmToFeetParts(json_item.default_ref_level_offset)[0] || 0; e.target.blur() } }} defaultValue={mmToFeetParts(json_item.default_ref_level_offset)[0] || 0} key={"key_" + json_item.id + "_offset" + "_ft"} id={json_item.id + "_offset" + "_ft"} style={{ ...styles_panel.input_styles, border: 'none', height: '24px' }} placeholder="0" />
                                    //             <div className='pr-1 flex_property' style={{ fontSize: 10, textAlign: 'right', cursor: "default", color: COLORS.gray5, height: '24px' }}>ft</div>
                                    //         </Col>
                                    //         <Col className='flex_property class_position_unset zero_padding' style={{ borderRight: '1px solid ' + COLORS.panel_item_borders, width: '30%' }}>
                                    //             <Input type="text" onChange={common_onchange_handler} onKeyDown={(e) => { if (e.key === 'Enter') { e.target.blur() } else if (e.key == 'Escape') { e.target.value = mmToFeetParts(json_item.default_ref_level_offset)[1] || 0; e.target.blur() } }} defaultValue={mmToFeetParts(json_item.default_ref_level_offset)[1] || 0} key={"key_" + json_item.id + "_offset" + "_in"} id={json_item.id + "_offset" + "_in"} style={{ ...styles_panel.input_styles, border: 'none', height: '24px' }} placeholder="0" />
                                    //             <div className='pr-1 flex_property' style={{ fontSize: 10, textAlign: 'right', cursor: "default", color: COLORS.gray5, height: '24px' }}>in</div>
                                    //         </Col>
                                    //         <Col className='flex_property class_position_unset zero_padding' style={{ width: '30%' }}>
                                    //             <Input type="text" onChange={common_onchange_handler} onKeyDown={(e) => { if (e.key === 'Enter') { e.target.blur() } else if (e.key == 'Escape') { e.target.value = mmToFeetParts(json_item.default_ref_level_offset)[2] || 0; e.target.blur() } }} defaultValue={mmToFeetParts(json_item.default_ref_level_offset)[2] || 0} key={"key_" + json_item.id + "_offset" + "_parts"} id={json_item.id + "_offset" + "_parts"} style={{ ...styles_panel.input_styles, border: 'none', height: '24px' }} placeholder="0" />
                                    //             <div className='pr-1 flex_property' style={{ fontSize: 10, textAlign: 'right', cursor: "default", color: COLORS.gray5, height: '24px' }}>{approximation_ft == '1/8_inch' ? '1/8' : '1/16'}</div>
                                    //         </Col>
                                    //     </FocusWithin>
                                    // </Col>
                                    <DimensionInputFtIn json_item={json_item} defaultValueKey='default_ref_level_offset' on_click={on_click} common_onchange_handler={common_onchange_handler} mmToFeetParts={mmToFeetParts} approximation_ft={approximation_ft} />

                                )}
                            </React.Fragment>
                        )}
                    </div>
                </div>
            </div>
        </PanelCard>
    );
}

const DimensionInputFtIn = ({ json_item, defaultValueKey = "default_value", idKey = "id", getName, is_grouped, configuration_type, on_click, common_onchange_handler, mmToFeetParts, approximation_ft }) => {

    return (
        <div className='createcard_input_container_new input_small blue_highlight_focuswithin'>
            {!showHeader(is_grouped, configuration_type) ? <PanelItemHeader json_item={json_item} trim={1} configuration_type={configuration_type} getName={getName ? getName : (json_item) => json_item.name} /> : ''}

            <div className='flex-1-mw'>
                <FocusWithin onBlur={on_click} style={{ display: 'flex', width: '100%' }}>
                    <div className='flex_property zero_padding' style={{ borderRight: '1px solid ' + COLORS.panel_item_borders, width: '40%' }}>
                        <Input type="text" onChange={common_onchange_handler} onKeyDown={(e) => { if (e.key === 'Enter') { e.target.blur() } else if (e.key == 'Escape') { e.target.value = mmToFeetParts(json_item[defaultValueKey])[0] || 0; e.target.blur() } }} defaultValue={mmToFeetParts(json_item[defaultValueKey])[0] || 0} key={"key_" + json_item[idKey] + "_ft"} id={json_item[idKey] + "_ft"} style={{ ...styles_panel.input_styles, border: 'none', height: '20px', padding: '0px' }} placeholder={json_item.mixed ? 'Mixed' : "0"} />
                        <div className='pr-1 flex_property' style={{ fontSize: 10, textAlign: 'right', cursor: "default", color: COLORS.gray5, height: '20px' }}>ft</div>
                    </div>
                    <div className='flex_property inf-pl-1' style={{ borderRight: '1px solid ' + COLORS.panel_item_borders, width: '30%' }}>
                        <Input type="text" onChange={common_onchange_handler} onKeyDown={(e) => { if (e.key === 'Enter') { e.target.blur() } else if (e.key == 'Escape') { e.target.value = mmToFeetParts(json_item[defaultValueKey])[1] || 0; e.target.blur() } }} defaultValue={mmToFeetParts(json_item[defaultValueKey])[1] || 0} key={"key_" + json_item[idKey] + "_in"} id={json_item[idKey] + "_in"} style={{ ...styles_panel.input_styles, border: 'none', height: '20px' }} placeholder={json_item.mixed ? 'Mixed' : "0"} />
                        <div className='pr-1 flex_property' style={{ fontSize: 10, textAlign: 'right', cursor: "default", color: COLORS.gray5, height: '20px' }}>in</div>
                    </div>
                    <div className='flex_property inf-pl-1' style={{ width: '30%' }}>
                        <Input type="text" onChange={common_onchange_handler} onKeyDown={(e) => { if (e.key === 'Enter') { e.target.blur() } else if (e.key == 'Escape') { e.target.value = mmToFeetParts(json_item[defaultValueKey])[2] || 0; e.target.blur() } }} defaultValue={mmToFeetParts(json_item[defaultValueKey])[2] || 0} key={"key_" + json_item[idKey] + "_parts"} id={json_item[idKey] + "_parts"} style={{ ...styles_panel.input_styles, border: 'none', height: '20px' }} placeholder={json_item.mixed ? 'Mixed' : "0"} />
                        <div className='flex_property' style={{ fontSize: 10, textAlign: 'right', cursor: "default", color: COLORS.gray5, height: '20px' }}>{approximation_ft == '1/8_inch' ? '1/8' : '1/16'}</div>
                    </div>
                </FocusWithin>
            </div>
        </div>
    )
}

const MultiComponentInputSingleInput = ({ input }) => {
    const [value, set_value] = useState('');
    useEffect(() => {
        if (value !== undefined && value !== input.value) {
            set_value(input.default_value)
        }
    }, [input]);
    return (
        <div className='panel_multi_component_single_input_container'>
            <div>{input.name}</div>
            {
                input.type === "select" ?
                    <select value={value} onChange={(e) => { set_value(e.target.value) }}>
                        {
                            input.options && input.options.length ? input.options.map((option) => (
                                <option key={option.value} value={option.value}>{option.name}</option>
                            ))
                                : ''
                        }
                    </select>
                    :
                    <input value={value} onChange={(e) => { set_value(e.target.value) }} type={input.type} />
            }
        </div>
    )
}

export const MultiComponentInput = ({ json_item, common_onchange_handler, on_click, parent }) => {
    return (
        // Heading can be above or inline

        <PanelCard json_item={json_item} key={json_item.id} disabled={json_item.disabled}>
            {/* header_type -> names_above */}
            <div className='panel_wrap_container'>
                <PanelItemHeader json_item={json_item} configuration_type={json_item.header_type} />
                {
                    json_item.inputs && json_item.inputs.length ?
                        <div className='panel_multi_component_input_container'>
                            {
                                json_item.inputs.map(input => (
                                    <MultiComponentInputSingleInput input={input} />
                                ))
                            }
                        </div>
                        : ''
                }
            </div>
        </PanelCard>
    )
}

export const DimensionInput = ({ json_item, on_click, common_onchange_handler, metric_system, is_grouped, configuration_type, approxMM, mmToFeetParts, approximation_ft }) => {

    return (
        json_item.expression_available_parameters ? <ParametricDimensionInput json_item={json_item} common_onchange_handler={common_onchange_handler} on_click={on_click} configuration_type={configuration_type} is_section={true}></ParametricDimensionInput> :
            <PanelCard json_item={json_item}>
                {(metric_system == 'mm' || json_item.is_unitless_true) ? (
                    <div className='panel_flex_property_wrap'>
                        {
                            showHeader(is_grouped, configuration_type) ? <PanelItemHeader json_item={json_item} configuration_type={configuration_type} /> : ''
                        }
                        <div className='flex-1-mw'>
                            <div className='createcard_input_container_new input_small'>
                                {!showHeader(is_grouped, configuration_type) ? <PanelItemHeader json_item={json_item} configuration_type={configuration_type} trim={1} /> : ''}
                                {/* <PanelInput type="text" onChange={common_onchange_handler} id={json_item.id} key={"key_" + json_item.id} onBlur={on_click} defaultValue={json_item.mixed ? '' : json_item.parameter_expression ? json_item.parameter_expression : (approxMM(json_item.default_value) || 0)} placeholder={json_item.mixed ? 'Mixed' : "0"} mixed={json_item.mixed ? 'Mixed' : ""} /> */}
                                {PanelInput({ type: "text", expectedType: "number", onChange: common_onchange_handler, id: json_item.id, onBlur: on_click, defaultValue: json_item.mixed ? '' : json_item.parameter_expression ? json_item.parameter_expression : (approxMM(json_item.default_value) || 0), placeholder: json_item.mixed ? 'Mixed' : "0", mixed: json_item.mixed ? 'Mixed' : "" })}
                            </div>
                        </div>
                    </div>
                ) : (
                    // is_grouped ?
                    <div className='panel_flex_property_wrap'>
                        {showHeader(is_grouped, configuration_type) ? <PanelItemHeader json_item={json_item} configuration_type={configuration_type} /> : ''}
                        <DimensionInputFtIn json_item={json_item} is_grouped={is_grouped} configuration_type={configuration_type} on_click={on_click} common_onchange_handler={common_onchange_handler} mmToFeetParts={mmToFeetParts} approximation_ft={approximation_ft} />
                    </div>
                    // :
                    // <Row>
                    //     <Col sm={5} style={{ fontSize: 12, textAlign: 'left', cursor: 'default' }}><span title={json_item.name} className='lines2_elipsis' >{json_item.name}</span></Col>
                    //     <Col sm={6} className='zero_padding createcard_input_container_new input_small blue_highlight_focuswithin' style={{ height: 'fit-content' }}>
                    //         <FocusWithin onBlur={on_click} style={{ display: 'flex', width: '100%' }}>
                    //             <Col className='flex_property zero_padding' style={{ borderRight: '1px solid ' + COLORS.panel_item_borders, width: '40%' }}>
                    //                 <Input type="text" onChange={common_onchange_handler} onKeyDown={(e) => { if (e.key === 'Enter') { e.target.blur() } else if (e.key == 'Escape') { e.target.value = mmToFeetParts(json_item.default_value)[0] || 0; e.target.blur() } }} defaultValue={mmToFeetParts(json_item.default_value)[0] || 0} key={"key_" + json_item.id + "_ft"} id={json_item.id + "_ft"} style={{ ...styles_panel.input_styles, border: 'none', height: '20px', padding: '0px' }} placeholder={json_item.mixed ? 'Mixed' : "0"} />
                    //                 <div className='pr-1 flex_property' style={{ fontSize: 10, textAlign: 'right', cursor: "default", color: COLORS.gray5, height: '20px' }}>ft</div>
                    //             </Col>
                    //             <Col className='flex_property zero_padding' style={{ borderRight: '1px solid ' + COLORS.panel_item_borders, width: '30%' }}>
                    //                 <Input type="text" onChange={common_onchange_handler} onKeyDown={(e) => { if (e.key === 'Enter') { e.target.blur() } else if (e.key == 'Escape') { e.target.value = mmToFeetParts(json_item.default_value)[1] || 0; e.target.blur() } }} defaultValue={mmToFeetParts(json_item.default_value)[1] || 0} key={"key_" + json_item.id + "_in"} id={json_item.id + "_in"} style={{ ...styles_panel.input_styles, border: 'none', height: '20px' }} placeholder={json_item.mixed ? 'Mixed' : "0"} />
                    //                 <div className='pr-1 flex_property' style={{ fontSize: 10, textAlign: 'right', cursor: "default", color: COLORS.gray5, height: '20px' }}>in</div>
                    //             </Col>
                    //             <Col className='flex_property zero_padding' style={{ width: '30%' }}>
                    //                 <Input type="text" onChange={common_onchange_handler} onKeyDown={(e) => { if (e.key === 'Enter') { e.target.blur() } else if (e.key == 'Escape') { e.target.value = mmToFeetParts(json_item.default_value)[2] || 0; e.target.blur() } }} defaultValue={mmToFeetParts(json_item.default_value)[2] || 0} key={"key_" + json_item.id + "_parts"} id={json_item.id + "_parts"} style={{ ...styles_panel.input_styles, border: 'none', height: '20px' }} placeholder={json_item.mixed ? 'Mixed' : "0"} />
                    //                 <div className='flex_property' style={{ fontSize: 10, textAlign: 'right', cursor: "default", color: COLORS.gray5, height: '20px' }}>{approximation_ft == '1/8_inch' ? '1/8' : '1/16'}</div>
                    //             </Col>
                    //         </FocusWithin>
                    //     </Col>
                    // </Row>
                )}
            </PanelCard>

    )
}

export const SelectableButton = ({ json_item, on_click, common_onchange_handler, ...props }) => {
    return (
        <PanelCard json_item={json_item}>
            <input className='hide' value={json_item.default_value} id={json_item.id} />
            <div className={`panel_selectable_button ${json_item.default_value === "true" ? 'active' : ''}`} {...props} onClick={() => { common_onchange_handler(); on_click({ item_value: json_item.default_value === "true" ? "false" : "true" }) }}>
                <Icons name={json_item.name} />
            </div>
        </PanelCard>
    )
}

export const SelectInputNew = ({ json_item, on_click, is_grouped, configuration_type, common_onchange_handler }) => {
    const [select_input_value, set_select_input_value] = useState('');
    const [select_input_value_changed, set_select_input_value_changed] = useState({ status: false });

    useEffect(() => {
        if (select_input_value && select_input_value_changed.status /* && (!(!json_item.mixed && select_input_value == json_item.default_value)) */) {
            on_click()
            select_input_value_changed.status = false
        }
    }, [select_input_value]);

    useEffect(() => {
        select_input_value_changed.status = false
        if (json_item.mixed) {
            set_select_input_value('')
        } else {
            set_select_input_value(json_item.default_value)
        }
    }, [json_item.default_value]);
    return (
        <PanelCard json_item={json_item}>
            {
                // json_item.options.length == 2 && json_item.onchange_route && !(configuration_type == 'group_by_2') && !(json_item.type == 'layer_input') ?
                //     configuration_type == 'names_above' ?
                //         <div>
                //             <div style={{ maxWidth: '100%' }}>
                //                 <div style={{ fontSize: '12px', display: "flex", alignItems: "center", cursor: "default", color: json_item.color ? json_item.color : COLORS.gray6, marginBottom: '4px' }}><span title={json_item.name} className='lines1_elipsis' >{json_item.name}</span></div>
                //                 <div style={{ display: "flex", alignItems: "center" }}>
                //                     <div style={{ width: '100%', display: 'flex', fontSize: '12px' }}>
                //                         <Input key={"key_" + json_item.id} id={json_item.id} style={{ display: 'none' }} type="text" value={select_input_value} readOnly />
                //                         <div className='lines1_elipsis flex_center' key={1} onClick={() => { select_input_value_changed.status = true; set_select_input_value(json_item.options[0].value); common_onchange_handler()/*; on_click(); on_select_input_change(json_item.options[0].value)*/ }} style={json_item.options[0].value == json_item.default_value ? { width: '50%', borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px', border: '1px solid', borderColor: COLORS.blue6, padding: '4px', cursor: 'pointer', fontWeight: 700, color: COLORS.blue6 } : { width: '50%', borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px', border: '1px solid', borderColor: '#C5C7CF', borderRightColor: json_item.options[1].value == json_item.default_value ? 'transparent' : '#C5C7CF', padding: '4px', cursor: 'pointer', backgroundColor: COLORS.gray2 }} title={json_item.options[0].name}>
                //                             {json_item.options[0].icon ? <img className='mr-2' src={json_item.options[0].icon}></img> : ''}
                //                             <div>{json_item.options[0].name}</div>
                //                         </div>
                //                         <div className='lines1_elipsis flex_center' key={2} onClick={() => { select_input_value_changed.status = true; set_select_input_value(json_item.options[1].value); common_onchange_handler();/*; on_click(); on_select_input_change(json_item.options[1].value)*/ }} style={json_item.options[1].value == json_item.default_value ? { width: '50%', borderTopRightRadius: '5px', borderBottomRightRadius: '5px', border: '1px solid', borderColor: COLORS.blue6, padding: '4px', cursor: 'pointer', fontWeight: 700, color: COLORS.blue6 } : { width: '50%', borderTopRightRadius: '5px', borderBottomRightRadius: '5px', border: '1px solid', borderColor: '#C5C7CF', borderLeftColor: 'transparent', padding: '4px', cursor: 'pointer', backgroundColor: COLORS.gray2 }} title={json_item.options[1].name}>
                //                             {json_item.options[1].icon ? <img className='mr-2' src={json_item.options[1].icon}></img> : ''}
                //                             <div>{json_item.options[1].name}</div>
                //                         </div>
                //                     </div>
                //                 </div>
                //             </div>
                //         </div>
                //         :
                //         <div className='flex_property'>
                //             <div xs={3} style={{ flex: 1, fontSize: '12px', display: "flex", alignItems: "center", cursor: "default" }}><span title={json_item.name} className='lines1_elipsis' >{json_item.name}</span></div>
                //             <div xs={9} style={{ flex: 3, display: "flex", alignItems: "center", justifyContent: 'center' }}>
                //                 <div style={{ width: '100%', display: 'flex', fontSize: '12px' }}>
                //                     <Input key={"key_" + json_item.id} id={json_item.id} style={{ display: 'none' }} type="text" value={select_input_value} readOnly />
                //                     <div className='lines1_elipsis flex_center' key={1} onClick={() => { select_input_value_changed.status = true; set_select_input_value(json_item.options[0].value); common_onchange_handler();/*; on_click(); on_select_input_change(json_item.options[0].value)*/ }} style={json_item.options[0].value == json_item.default_value ? { width: '50%', borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px', border: '1px solid', borderColor: COLORS.blue6, padding: '4px', cursor: 'pointer', fontWeight: 700, color: COLORS.blue6 } : { width: '50%', borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px', border: '1px solid', borderColor: '#C5C7CF', borderRightColor: json_item.options[1].value == json_item.default_value ? 'transparent' : '#C5C7CF', padding: '4px', cursor: 'pointer', backgroundColor: COLORS.gray2 }} title={json_item.options[0].name}>
                //                         {json_item.options[0].icon ? <img className='mr-2' src={json_item.options[0].icon}></img> : ''}
                //                         {json_item.options[0].name}
                //                     </div>
                //                     <div className='lines1_elipsis flex_center' key={2} onClick={() => { select_input_value_changed.status = true; set_select_input_value(json_item.options[1].value); common_onchange_handler();/*; on_click(); on_select_input_change(json_item.options[1].value)*/ }} style={json_item.options[1].value == json_item.default_value ? { width: '50%', borderTopRightRadius: '5px', borderBottomRightRadius: '5px', border: '1px solid', borderColor: COLORS.blue6, padding: '4px', cursor: 'pointer', fontWeight: 700, color: COLORS.blue6 } : { width: '50%', borderTopRightRadius: '5px', borderBottomRightRadius: '5px', border: '1px solid', borderColor: '#C5C7CF', borderLeftColor: 'transparent', padding: '4px', cursor: 'pointer', backgroundColor: COLORS.gray2 }} title={json_item.options[1].name}>
                //                         {json_item.options[1].icon ? <img className='mr-2' src={json_item.options[1].icon}></img> : ''}
                //                         {json_item.options[1].name}
                //                     </div>
                //                 </div>
                //             </div>
                //         </div>
                //     :
                <div className='panel_flex_property_wrap'>
                    {
                        showHeader(is_grouped, configuration_type) ? <PanelItemHeader json_item={json_item} configuration_type={configuration_type} /> : ''
                    }
                    {
                        json_item.options.length == 2 && json_item.onchange_route && !(configuration_type == 'group_by_2') && !(json_item.type == 'layer_input') ?
                            <div className='flex_property flex-2-mw'>
                                <Input key={"key_" + json_item.id} id={json_item.id} style={{ display: 'none' }} type="text" value={select_input_value} readOnly />
                                <div className='lines1_elipsis flex_center' key={1} onClick={() => { select_input_value_changed.status = true; set_select_input_value(json_item.options[0].value); common_onchange_handler()/*; on_click(); on_select_input_change(json_item.options[0].value)*/ }} style={json_item.options[0].value == json_item.default_value ? { width: '50%', borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px', border: '1px solid', borderColor: COLORS.blue6, padding: '4px', cursor: 'pointer', fontWeight: 700, color: COLORS.blue6 } : { width: '50%', borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px', border: '1px solid', borderColor: '#C5C7CF', borderRightColor: json_item.options[1].value == json_item.default_value ? 'transparent' : '#C5C7CF', padding: '4px', cursor: 'pointer', backgroundColor: COLORS.gray2 }} title={json_item.options[0].name}>
                                    {json_item.options[0].icon ? <img className='mr-2' src={json_item.options[0].icon}></img> : ''}
                                    <div>{json_item.options[0].name}</div>
                                </div>
                                <div className='lines1_elipsis flex_center' key={2} onClick={() => { select_input_value_changed.status = true; set_select_input_value(json_item.options[1].value); common_onchange_handler();/*; on_click(); on_select_input_change(json_item.options[1].value)*/ }} style={json_item.options[1].value == json_item.default_value ? { width: '50%', borderTopRightRadius: '5px', borderBottomRightRadius: '5px', border: '1px solid', borderColor: COLORS.blue6, padding: '4px', cursor: 'pointer', fontWeight: 700, color: COLORS.blue6 } : { width: '50%', borderTopRightRadius: '5px', borderBottomRightRadius: '5px', border: '1px solid', borderColor: '#C5C7CF', borderLeftColor: 'transparent', padding: '4px', cursor: 'pointer', backgroundColor: COLORS.gray2 }} title={json_item.options[1].name}>
                                    {json_item.options[1].icon ? <img className='mr-2' src={json_item.options[1].icon}></img> : ''}
                                    <div>{json_item.options[1].name}</div>
                                </div>
                            </div>
                            :
                            <div className='flex-1-mw'>
                                <div className='createcard_input_container_new input_small'>

                                    <Input onChange={(e) => { select_input_value_changed.status = true; set_select_input_value(e.target.value); common_onchange_handler(); }} value={select_input_value !== '' ? select_input_value : json_item.mixed ? '' : json_item.default_value} key={"key_" + json_item.id} id={json_item.id} style={{ ...styles_panel.input_styles, border: 'none', height: '20px' }} type="select">
                                        {
                                            json_item.mixed ? <option value={''}>{'Mixed'}</option> : ''
                                        }
                                        {
                                            json_item.options.map((o, idx) => (
                                                <option key={idx} value={o.value}>{o.name}</option>
                                            ))
                                        }
                                    </Input>
                                </div>
                            </div>
                    }
                </div>
            }
        </PanelCard>
    )
}

export const ImageMapSelectNew = ({json_item, on_click, common_onchange_handler, icon_on_click}) => {
	const [value, set_value] = useState('');

	useEffect(() => {
		if(json_item){
			set_value(json_item.default_value)
		}
	}, [json_item.default_value]);

	useEffect(() => {
		if(value && value != json_item.default_value){
			on_click()
		}
	}, [value]);

	//TODO: Can further extend the styling based on the requirement

    const active_option_name = value && json_item.options && json_item.options.find(x => x.value == value) ? json_item.options.find(x => x.value == value).name : ''
	
	return (
		<PanelCard json_item={json_item}>
			<input style={{display: 'none'}} value={value} id={json_item.id}/>
			<div style={{width: '100%', display: 'flex', alignItems: 'center', gap: '8px'}}>
				{json_item.image_placement === 'inline_left' ? <IImg className='inf-border' src={json_item.icon} height={'32px'} style={{maxWidth:'64px', objectFit: 'cover', borderRadius: '2px'}}></IImg> : ''}
				<AntdDropdown disabled={json_item.disabled} trigger={['click']}
                    menu={{
                        onClick: ({key: value}) => {common_onchange_handler(); set_value(value)},
                        items:(
                            json_item.options && json_item.options.map((option, idx) => {
                                return {
                                    key: option.value,
                                    label: <div className='flex_property inf-gap-2'>
									<IImg height={'32px'} width={'64px'} style={{objectFit: 'cover'}} src={option.icon} ></IImg>
									<div className='lines1_elipsis' title={option.name}>{option.name}</div>
								</div>
                            }
                        }))
                    }}>
					<div className='flex_property flex-1-mw inf-border inf-px-2 inf-py-1'>
                        <div className='flex-1-mw lines1_elipsis' title={active_option_name}>{active_option_name}</div>
                        <i className='fa fa-caret-down' />
					</div>
				</AntdDropdown>
				{
					json_item.buttons && json_item.buttons.length ? <ButtonsArray button_list={json_item.buttons} icon_on_click={icon_on_click}/> : ''
				}
			</div>
			{!json_item.image_placement ? <IImg className='mt-2 inf-border' src={json_item.icon} width={'100%'} style={{borderRadius: '2px'}}></IImg> : ''}
		</PanelCard>
	)
}

export const NumericInputNew = ({ json_item, on_click, common_onchange_handler, is_grouped, configuration_type }) => {

    const [value, setValue] = useState(0);
    const [input_changed, set_input_changed] = useState(false);

    const handleChange = (event) => {
        set_input_changed(true)
        common_onchange_handler()
        setValue(String(parseFloat(event.target.value ? event.target.value : 0)));
    };

    useEffect(() => {
        set_input_changed(false)
        if (json_item.default_value) {
            setValue(json_item.default_value ? String(Math.floor(parseFloat(json_item.default_value))) : 0)
        } else {
            setValue(0)
        }
    }, [json_item.default_value]);

    return (
        <PanelCard json_item={json_item}>
            {
                <div className='panel_flex_property_wrap'>
                    {
                        showHeader(is_grouped, configuration_type) ? <PanelItemHeader json_item={json_item} configuration_type={configuration_type} /> : ''
                    }
                    <div className='flex-1-mw'>

                        <div className='createcard_input_container_new input_small'>
                            {is_grouped ? <div style={{ color: json_item.color ? json_item.color : '', display: configuration_type == 'group_by_2' || configuration_type == 'names_above' ? 'none' : 'flex', alignItems: 'center', justifyContent: 'center' }}>{json_item.name ? json_item.name[0] : ''}</div> : ''}
                            <div className={json_item.numeric_integer_input ? 'panel_numeric_integer_input flex_property' : ''} data-decimal={json_item.numeric_integer_input ? json_item.default_value ? json_item.default_value % 1 != 0 ? String((json_item.default_value % 1).toFixed(2)).substring(1) : '' : '' : ''}>
                                <Input disabled={json_item.disabled} type="text" onChange={handleChange} onBlur={() => { console.log("on click", on_click); on_click() }} onKeyDown={(e) => { if (e.key === 'Enter') { e.target.blur() } else if (e.key == 'Escape') { setValue((json_item.default_value) || ''); e.target.blur() } }} value={value} key={"key_" + json_item.id + "display"} id={json_item.id + "display"} style={{ width: `${(value.length * 7) + 7}px`, minWidth: '10px' }} placeholder="0"></Input>
                                <Input disabled={json_item.disabled} type="text" value={input_changed ? value : json_item.default_value} key={"key_" + json_item.id} id={json_item.id} style={{ display: 'none' }} placeholder="0"></Input>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </PanelCard>
    )
}

export const ColorPickerNew = ({ json_item, configuration_type, is_grouped, common_onchange_handler, on_click }) => {
    const [value, set_value] = useState('');

    useEffect(() => {
        if (json_item && json_item.default_value != value) {
            set_value(json_item.default_value)
        }
    }, [json_item]);

    return (
        <PanelCard json_item={json_item}>
            {
                <div className='panel_flex_property_wrap'>
                    {
                        showHeader(is_grouped, configuration_type) ? <PanelItemHeader json_item={json_item} configuration_type={configuration_type} /> : ''
                    }
                    <div className='createcard_input_container_new input_small'>
                        {
                            !json_item.is_kelvin ?
                                <React.Fragment>
                                    <Input type="color" onChange={common_onchange_handler} onBlur={on_click} onKeyDown={(e) => { if (e.key === 'Enter') { e.target.blur() } else if (e.key == 'Escape') { e.target.value = (json_item.default_value); e.target.blur() } }} defaultValue={(json_item.mixed ? '' : json_item.default_value)} key={"key_color" + json_item.id} id={json_item.id} style={{ ...styles_panel.input_styles, border: 'none', flex: json_item.mixed ? 'unset' : 5, height: '20px', padding: 0, width: json_item.mixed ? '24px' : '100%' }} />
                                    {json_item.mixed ? <div className='flex_property' style={{ ...styles_panel.input_styles, border: 'none', flex: 5, height: '20px', padding: 0 }} onClick={() => { document.getElementById(json_item.id).focus(); document.getElementById(json_item.id).click() }}>Mixed</div> : ''}
                                </React.Fragment>
                                :
                                <KelvinColorPicker json_item={json_item} common_onchange_handler={common_onchange_handler} on_click={on_click} />
                        }
                    </div>
                </div>
            }
        </PanelCard>
    )
}

export const RotationInputNew = ({ json_item, on_click, common_onchange_handler, icon_on_click }) => {

    return (
        <PanelCard json_item={json_item}>
            {/* <div className='panel_section_header_2' style={{ fontSize: 12, alignItems: "center", cursor: "default", fontWeight: 500, color: COLORS.panel_group_heading, display: json_item.onchange_route ? 'flex' : 'none' }}><span title={json_item.name} className='lines1_elipsis' >{"Rotation(deg)"}</span></div> */}
            {/* <input key={"key_" + json_item.id} value={rotation_input_final} type="number" id={json_item.id} style={{ display: 'none' }} readOnly /> */}
            <div className='flex_property'>
                <div className='flex_property flex-1-mw'>
                    {
                        json_item.onchange_route ?
                            <div className='createcard_input_container_new input_small' style={{ display: 'flex', width: '100%', border: '1px solid ' + COLORS.panel_item_borders, padding: '0px 4px' }}>
                                <div style={{ color: json_item.onchange_route ? '#FF5247' : COLORS.panel_group_heading, fontWeight: json_item.onchange_route ? 400 : 700, fontSize: '12px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{json_item.name}</div>
                                <PanelInput key={"key_" + json_item.id} id={json_item.id} defaultValue={json_item.default_value} onChange={(e) => { common_onchange_handler() }} onBlur={on_click} type="number" style={{ display: json_item.onchange_route ? 'block' : 'none' }} mixed={json_item.mixed ? 'Mixed' : ''} />
                            </div>
                            :
                            <div style={{ fontSize: '12px', color: '#676878' }}>Rotation:</div>
                    }
                </div>
                <div style={{ flex: 2, minWidth: '0px', fontSize: 12, display: 'flex', alignItems: "center", justifyContent: 'flex-end', gap: '8px' }}>
                    <span style={{ fontSize: '10px', maxWidth: '110px', textOverflow: 'ellipsis', color: COLORS.gray5 }} title={json_item.text_content ? json_item.text_content : ''}>{json_item.onchange_route ? "Rotate by " + (json_item.text_content ? json_item.text_content : '90') + ' deg' : json_item.text_content}</span>
                    <img src={"/resources/panel_icons_new/rotate left.svg"} style={{ cursor: 'pointer', height: '16px' }} onClick={json_item.onchange_route ? () => { common_onchange_handler(); on_click({ item_value: json_item.default_value - (json_item.text_content ? json_item.text_content : 90) }); /*icon_on_click({route: json_item.left_rotation_route, form:{id: json_item.left_id}}) on_click()*/ } : () => { icon_on_click({ route: json_item.left_rotation_route, form: { id: json_item.left_id } }) }} />
                    <img src={"/resources/panel_icons_new/rotate right.svg"} style={{ cursor: 'pointer', height: '16px' }} onClick={json_item.onchange_route ? () => { common_onchange_handler(); on_click({ item_value: json_item.default_value - ('-' + (json_item.text_content ? json_item.text_content : 90)) }); /*icon_on_click({route: json_item.right_rotation_route, form:{id: json_item.right_id}}) on_click()*/ } : () => { icon_on_click({ route: json_item.right_rotation_route, form: { id: json_item.right_id } }) }} />
                </div>
            </div>
        </PanelCard>
    )
}

export const CheckboxInputNew = ({ json_item, common_onchange_handler, on_click }) => {
    const [value, set_value] = useState(false);

    useEffect(() => {
        if (json_item && value != json_item.selected) {
            set_value(json_item.selected)
        }
    }, [json_item]);

    return (
        <PanelCard json_item={json_item}>
            <div className='flex_between panel_checkbox_input_new' /* inf-py-1 hover_to_gray2 */ onClick={() => { document.getElementById(json_item.id).click() }}>
                <div style={{ display: "flex", alignItems: "center", gap: '6px', flex: 1, minWidth: '0px' }}>
                    {
                        json_item.icon ? (
                            <img height='16px' title={json_item.name} src={json_item.icon} />
                        ) : ''
                    }
                    <div title={json_item.name} style={{ flex: 1, minWidth: '0px' }} className='lines1_elipsis' >{json_item.name}</div>
                </div>
                <input onClick={(e) => { e.stopPropagation(); common_onchange_handler(); on_click({ item_value: !e.target.value }); set_value(!e.target.value) }} key={"key_" + json_item.id} id={json_item.id} type="checkbox" checked={value}></input>
            </div>
        </PanelCard>
    )
}

export const ToggleInputNew = ({ json_item, common_onchange_handler, on_click }) => {
    const [value, set_value] = useState(false);

    useEffect(() => {
        if (json_item && value != json_item.value) {
            set_value(json_item.value)
        }
    }, [json_item]);

    return (
        <PanelCard json_item={json_item}>
            <div className='flex_between'>
                <div title={json_item.name} className='lines1_elipsis' style={{ flex: 1, minWidth: '1px' }}>{json_item.name}</div>
                <div className='ml-2'>
                    <Switch size='small' disabled={json_item.disabled} key={"key_" + json_item.id} id={json_item.id} checked={value} onChange={(checked) => { set_value(checked); common_onchange_handler(); on_click({ item_value: checked }) }} />
                </div>
            </div>
        </PanelCard>
    )
}

export const TextInputNew = ({ json_item, common_onchange_handler, on_click, is_grouped, configuration_type }) => {
    return (
        <PanelCard json_item={json_item}>
            <div className='panel_flex_property_wrap'>
                {showHeader(is_grouped, configuration_type) ? <PanelItemHeader json_item={json_item} configuration_type={configuration_type} /> : ''}
                <div className='flex-1-mw'>
                    <div className='createcard_input_container_new input_small'>
                        {!showHeader(is_grouped, configuration_type) ? <PanelItemHeader json_item={json_item} configuration_type={configuration_type} trim={1} /> : ''}
                        <PanelInput disabled={json_item.disabled} type="text" onChange={common_onchange_handler} onBlur={on_click} defaultValue={json_item.mixed ? '' : ((json_item.default_value) || '')} key={"key_" + json_item.id} id={json_item.id} placeholder={json_item.mixed ? "Mixed" : "0"} />
                    </div>
                </div>
            </div>
        </PanelCard>
    )
}

export const ColorInputNew = ({ json_item, common_onchange_handler, on_click, is_grouped, configuration_type }) => {
    return (
        <PanelCard json_item={json_item}>
            <div className='panel_flex_property_wrap'>
                {showHeader(is_grouped, configuration_type) ? <PanelItemHeader json_item={json_item} configuration_type={configuration_type} /> : ''}
                <div className='flex-1-mw'>
                    <div className='createcard_input_container_new input_small'>
                        {!showHeader(is_grouped, configuration_type) ? <PanelItemHeader json_item={json_item} configuration_type={configuration_type} trim={1} /> : ''}
                        <PanelInput disabled={json_item.disabled} type="color" onChange={common_onchange_handler} onBlur={on_click} onKeyDown={() => { }} defaultValue={json_item.mixed ? '' : ((json_item.default_value) || '')} key={"key_" + json_item.id} id={json_item.id} placeholder={json_item.mixed ? "Mixed" : "0"} />
                    </div>
                </div>
            </div>
        </PanelCard>
    )
}

export const MaterialSelectNew = ({ json_item, common_onchange_handler, is_panel_pop_up, update_view, handle_ui_response, mode, search_string_panel, parent }) => {
    const [is_options_dropdown_open, set_is_options_dropdown_open] = useState(false);
    const [edit_mode, set_edit_mode] = useState(false);
    return (
        <PanelCard json_item={json_item} className='material_select_new' style={{ backgroundColor: json_item.background_color ? json_item.background_color : '', display: 'flex', alignItems: 'center', gap: '12px' }}>
            <div style={{ flex: 1, minWidth: '0px' }}>
                <MaterialDropdownNew parent={parent} material_json={json_item} popup_panel={is_panel_pop_up} update_view={update_view} handle_ui_response={handle_ui_response} json_item={json_item} mode={mode} search_string_panel={search_string_panel} is_section={true} edit_mode={edit_mode} set_edit_mode={set_edit_mode} />
            </div>
            <div className='material_selector_new_buttons' style={{ alignSelf: json_item.is_mesh_material ? 'end' : '' }}>
                <div onClick={json_item.picker_disabled ? null : () => on_click_picker(json_item, is_panel_pop_up)} style={{ cursor: json_item.picker_disabled ? 'not-allowed' : 'pointer', opacity: json_item.picker_disabled ? 0.5 : 1 }}>
                    <Icons className={json_item.picker_disabled ? '' : 'blue_hover'} title='Material Picker: Choose existing material from viewport' style={{ fontSize: '16px' }} name="material_picker_new" />
                </div>
                <div onClick={json_item.fetch_store_disabled ? null : () => fetch_store_materials_onclick(json_item)} style={{ cursor: json_item.fetch_store_disabled ? 'not-allowed' : 'pointer', opacity: json_item.fetch_store_disabled ? 0.5 : 1 }}>
                    <Icons className={json_item.fetch_store_disabled ? '' : 'blue_hover'} title='Change from catalogue: opens carousal' name="add_from_store" style={{ fontSize: '16px' }} />
                </div>
                {
                    <AntdDropdown open={is_options_dropdown_open} trigger={['click']} placement='bottomRight' onOpenChange={(open) => set_is_options_dropdown_open(open)} menu={{
                        items: [
                            ...(
                                json_item.is_mesh_material ?
                                    [
                                        {
                                            key: '-1',
                                            label:
                                                <div className={!json_item.onchange_mesh_name ? 'disable_feedback' : ''} style={{ opacity: !json_item.onchange_mesh_name ? 0.5 : 1, cursor: !json_item.onchange_mesh_name ? 'not-allowed' : '' }} onClick={!json_item.onchange_mesh_name ? null : () => { set_is_options_dropdown_open(false); set_edit_mode((prev) => !prev) }}>
                                                    <div title={!json_item.onchange_mesh_name ? 'Can\'t clone materials imported with 3D models' : ''} style={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>
                                                        <img src='/resources/nav_icons_new/edit.svg' style={{ height: '16px', width: '16px' }} />
                                                        <div>Rename Mesh</div>
                                                    </div>
                                                </div>
                                        },
                                        {
                                            key: '0',
                                            type: 'divider'
                                        }
                                    ] : []
                            ),
                            {
                                key: '1',
                                label:
                                    <div className={json_item.clone_disabled ? 'disable_feedback' : ''} style={{ opacity: json_item.clone_disabled ? 0.5 : 1, cursor: json_item.clone_disabled ? 'not-allowed' : '' }} onClick={json_item.clone_disabled ? null : () => { set_is_options_dropdown_open(false); clone_active_modal_onclick(json_item, is_panel_pop_up) }}>
                                        <div title={json_item.clone_disabled ? 'Can\'t clone materials imported with 3D models' : ''} style={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>
                                            <img src='/resources/icons/Duplicate.svg' style={{ height: '16px', width: '16px' }} />
                                            <div>Clone material</div>
                                        </div>
                                    </div>
                            },
                            {
                                key: '2',
                                label:
                                    <div className={json_item.settings_disabled ? 'disable_feedback' : ''} style={{ opacity: json_item.settings_disabled ? 0.5 : 1, cursor: json_item.settings_disabled ? 'not-allowed' : '' }} onClick={json_item.settings_disabled ? null : () => { set_is_options_dropdown_open(false); open_material_settings(json_item) }}>
                                        <div title={json_item.settings_disabled ? '3d model\'s material can\'t be edited, please update to infurnia\'s material to access this option' : ''} style={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>
                                            <img src='/resources/icons/material_settings.svg' style={{ height: '16px', width: '16px' }} />
                                            <div>Open Material Settings</div>
                                        </div>
                                    </div>
                            },
                            {
                                key: '3',
                                label:
                                    <div className={json_item.reset_disabled ? 'disable_feedback' : ''} style={{ opacity: json_item.reset_disabled ? 0.5 : 1, cursor: json_item.reset_disabled ? 'not-allowed' : '' }} onClick={json_item.reset_disabled ? null : () => { set_is_options_dropdown_open(false); reset_material(json_item) }}>
                                        <div title={json_item.reset_disabled ? 'Can\'t reset to default here' : ''} style={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>
                                            <img src='/resources/icons/reset_default.svg' style={{ height: '16px', width: '16px' }} />
                                            <div>Reset to default</div>
                                        </div>
                                    </div>
                            }
                        ]
                    }}>
                        {/* <DropdownToggle style={{ display: 'flex', alignItems: 'center', background: 'transparent', padding: '0px', aspectRatio: '1', border: 'none', boxShadow: 'unset', color: 'unset' }}> */}
                        <Icons name='vertical_options' className={`blue_hover ${is_options_dropdown_open ? 'option_dropdown_open' : ''}`} style={{ fontSize: '16px' }} />
                        {/* </DropdownToggle> */}
                        {/* <DropdownMenu right container={'body'} className='DMSans' style={{ padding: '4px', marginTop: '40px' }}>
                                <DropdownItem className={json_item.clone_disabled ? 'disable_feedback' : ''} style={{ padding: '6px 12px', color: '#676878', opacity: json_item.clone_disabled ? 0.5 : 1, cursor: json_item.clone_disabled ? 'not-allowed' : '' }} onClick={json_item.clone_disabled ? null : () => clone_active_modal_onclick(json_item, is_panel_pop_up)}>
                                    <div title={json_item.clone_disabled ? 'Can\'t clone materials imported with 3D models' : ''} style={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>
                                        <img src='/resources/icons/Duplicate.svg' style={{ height: '16px', width: '16px' }} />
                                        <div>Clone material</div>
                                    </div>
                                </DropdownItem>
                                <DropdownItem className={json_item.settings_disabled ? 'disable_feedback' : ''} style={{ padding: '6px 12px', color: '#676878', opacity: json_item.settings_disabled ? 0.5 : 1, cursor: json_item.settings_disabled ? 'not-allowed' : '' }} onClick={json_item.settings_disabled ? null : () => open_material_settings(json_item)}>
                                    <div title={json_item.settings_disabled ? '3d model\'s material can\'t be edited, please update to infurnia\'s material to access this option' : ''} style={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>
                                        <img src='/resources/icons/material_settings.svg' style={{ height: '16px', width: '16px' }} />
                                        <div>Open Material Settings</div>
                                    </div>
                                </DropdownItem>
                                <DropdownItem className={json_item.reset_disabled ? 'disable_feedback' : ''} style={{ padding: '6px 12px', color: '#676878', opacity: json_item.reset_disabled ? 0.5 : 1, cursor: json_item.reset_disabled ? 'not-allowed' : '' }} onClick={json_item.reset_disabled ? null : () => reset_material(json_item)}>
                                    <div title={json_item.reset_disabled ? 'Can\'t reset to default here' : ''} style={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>
                                        <img src='/resources/icons/reset_default.svg' style={{ height: '16px', width: '16px' }} />
                                        <div>Reset to default</div>
                                    </div>
                                </DropdownItem>
                            </DropdownMenu> */}
                    </AntdDropdown>
                }
            </div>
        </PanelCard>
    )
}

export const LayerInputNew = ({ json_item, common_onchange_handler, on_click, configuration_type }) => {
    const [select_input_value, set_select_input_value] = useState('');
    const [selected_layer, set_selected_layer] = useState('');

    const switch_layer = (layer) => {
        common_onchange_handler()
        set_selected_layer(layer)
        set_select_input_value(layer.value)
    }

    useEffect(() => {
        if (select_input_value && (!(!json_item.mixed && select_input_value == json_item.default_value))) {
            on_click()
        }
    }, [select_input_value]);

    useEffect(() => {
        if (json_item.mixed) {
            set_select_input_value('')
        } else {
            set_select_input_value(json_item.default_value)
            let selected_item = json_item.options && json_item.options.find(item => item.value === json_item.default_value) || ''
            set_selected_layer(selected_item)
        }
    }, [json_item.default_value]);

    return (
        <PanelCard json_item={json_item}>
            <div className='flex_property' style={{ width: '100%' }}>
                <img src={'/resources/toolbar_icons_new/layers.svg'} className='mr-2' style={{ width: '20px', height: '20px' }} />
                <UncontrolledDropdown className='class_position_unset' style={{ flex: 1, minWidth: '40px', display: 'flex' }}>
                    <DropdownToggle style={{ flex: 1, minWidth: '40px', display: 'flex', border: '1px solid ' + COLORS.panel_item_borders, background: 'white', padding: '0px', boxShadow: 'none', fontSize: '12px', borderRadius: '2px' }}>
                        <div className={`${true ? '' : 'py-1'} px-2 flex_center`} style={{ height: '100%', borderRight: '1px solid ' + COLORS.panel_item_borders }} >
                            <div style={{ width: '16px', height: '16px', borderRadius: '1px', background: selected_layer && selected_layer.layer_color, border: '1px solid var(--light-border-color)' }} />
                        </div>
                        <div className={`${true ? '' : 'py-1'} px-2 flex_center`} style={{ height: '100%', borderRight: '1px solid ' + COLORS.panel_item_borders }} >
                            <div style={{ width: '24px', height: `${selected_layer && selected_layer.layer_thickness}px`, minHeight: '1px', maxHeight: '16px', backgroundColor: '#676878' }}></div>
                        </div>
                        <div className={`${true ? '' : 'py-1'} px-2`} style={{ height: true ? '20px' : '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', flex: 1, minWidth: '24px', color: '#676878' }}>
                            <div className='lines1_elipsis' title={selected_layer.name} style={{ width: 'calc(100% - 24px)', textAlign: 'left' }}>{selected_layer.name}</div>
                            <img src='/resources/toolbar_icons_new/dropdown.svg' width={'8px'} />
                        </div>
                    </DropdownToggle>
                    <DropdownMenu container={'body'} style={{ marginTop: true ? '16px' : '32px', width: true ? '208px' : '224px', maxHeight: '216px', overflow: 'auto', padding: '0px' }}>
                        {
                            json_item.options.map((layer, idx) => (
                                <DropdownItem style={{ listStyle: 'none', padding: '0px', color: '#676878', fontSize: '12px' }} onClick={() => { switch_layer(layer) }} key={idx}>
                                    <div style={{ display: 'flex', minWidth: '40px'/*, border: '1px solid ' + COLORS.panel_item_borders*/ }}>
                                        <div className='p-2 flex_center' style={{ /*borderRight: '1px solid ' + COLORS.panel_item_borders*/ }} >
                                            <div style={{ width: '16px', height: '16px', borderRadius: '1px', background: layer && layer.layer_color, border: '1px solid var(--light-border-color)' }} />
                                        </div>
                                        <div className='p-2 flex_center' style={{ /*borderRight: '1px solid ' + COLORS.panel_item_borders*/ }} >
                                            <div style={{ width: '24px', height: `${layer && layer.layer_thickness}px`, minHeight: '1px', maxHeight: '16px', backgroundColor: '#676878' }}></div>
                                        </div>
                                        <div className='p-2 lines1_elipsis' title={layer.name} style={{ textAlign: 'left', flex: 1, minWidth: '12px' }}>
                                            {layer.name}
                                        </div>
                                    </div>
                                </DropdownItem>
                            ))
                        }
                    </DropdownMenu>
                </UncontrolledDropdown>
            </div>

            <input value={select_input_value} id={json_item.id} style={{ display: 'none' }} />
        </PanelCard>
    )
}

// const SelectAndDimensionInputNew = ({}) => {
//     return(
//         <PanelCard json_item={json_item}>
//             {showHeader(is_grouped, configuration_type) ? <PanelItemHeader json_item={json_item} configuration_type={configuration_type} /> : ''}
//             <div className='createcard_input_container_new'>
//                 {!showHeader(is_grouped, configuration_type) ? <PanelItemHeader json_item={json_item} configuration_type={configuration_type} trim={1} /> : ''}
//                 <Input onChange={json_item.apply_on_select ? () => { common_onchange_handler(); on_click() } : () => { common_onchange_handler() }} key={"key_" + json_item.id_select + 'st'} id={json_item.id_select + 'st'} type="select" defaultValue={json_item.default_value_select} style={{ ...styles_panel.input_styles, border: 'none', height: '24px' }}>
//                     {json_item.options_select.map((o, idx) => (
//                         <option key={idx} value={o.value}>{o.name}</option>
//                     ))}
//                 </Input>
//             </div> 
//                     <Col xs={6} style={{fontSize:'12px', display:"flex", alignItems:"center", cursor:"default"}}>
//                     {metric_system=='mm'?(
//                                 <div style={{paddingRight:'8px', paddingLeft:'0px', marginLeft: -15, marginRight: -15}}>
//                                     <div className='createcard_input_container_new' style={{display:'flex', width:'100%', border:'1px solid ' + COLORS.panel_item_borders, padding:'0px 4px'}}>
//                                         <div className='lines1_elipsis' style={{flex:1, color: json_item.color ? json_item.color : COLORS.gray3 , fontSize:'11px', display:json_item.full_select ? 'none' :'flex', alignItems:'center', justifyContent:'center'}} title={json_item.name_dim}>{json_item.name_dim && json_item.name_dim.length ? json_item.name_dim[0] : ''}</div>
//                                         <Input type="text" onChange={common_onchange_handler} onBlur={on_click} onKeyDown={(e) => {if(e.key === 'Enter'){e.target.blur()}else if(e.key == 'Escape'){e.target.value=approxMM(json_item.default_value_dim) || 0 ; e.target.blur()}}} defaultValue={approxMM(json_item.default_value_dim)||0} key={"key_" + json_item.id_dim} id={json_item.id_dim} style={{...styles_panel.input_styles, border:'none', flex:5, height:'24px'}} placeholder="0"/>
//                                     </div>
//                                 </div>

//                         ):(
//                             <Row style={{paddingLeft:'8px', paddingRight:'8px'}}>
//                                 {/* <Col sm={5} className='zero_padding' style={{fontSize:13, textAlign:'left', cursor:'default'}}><span title={json_item.name} className='lines2_elipsis' >{json_item.name}</span></Col> */}
//                                 <Col sm={12} className='createcard_input_container_new' style={{border:'1px solid ' + COLORS.panel_item_borders}}>
//                                     {/* <Row style={{marginLeft:0, marginRight:0}}>
//                                         <Col sm={12} className='zero_padding' style={{fontSize:13, textAlign:'left', cursor:'default'}}><span title={json_item.name} className='lines2_elipsis' >{json_item.name}</span></Col>
//                                     </Row> */}
//                                     <Row style={{ padding: '0px 4px' }}>

//                                         <Col sm={json_item.full_select ? 0 : 1} className='zero_padding' style={{ fontSize: 13, textAlign: 'left', cursor: 'default', display: json_item.full_select ? 'none' : 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'center', color: json_item.color ? json_item.color : COLORS.gray3 }}><span title={json_item.name_dim} className='lines2_elipsis' >{json_item.name_dim && json_item.name_dim.length ? json_item.name_dim[0] : ''}</span></Col>

//                                         <Col xs={json_item.full_select ? 12 : 11} className='zero_padding'>
//                                             <FocusWithin onBlur={on_click} style={{ display: 'flex', width: '100%' }}>
//                                                 <Col className='zero_padding flex_property' style={{ borderRight: '1px solid ' + COLORS.panel_item_borders, width: '40%' }}>
//                                                     <Input type="text" onChange={common_onchange_handler} onKeyDown={(e) => { if (e.key === 'Enter') { e.target.blur() } else if (e.key == 'Escape') { e.target.value = mmToFeetParts(json_item.default_value_dim)[0] || 0; e.target.blur() } }} defaultValue={mmToFeetParts(json_item.default_value_dim)[0] || 0} key={"key_" + json_item.id_dim + "_ft"} id={json_item.id_dim + "_ft"} style={{ ...styles_panel.input_styles, border: 'none', height: '24px', padding: '0px' }} placeholder="0" />
//                                                     {/* <span style={{fontSize:13, textAlign:'left', cursor:"default"}}>ft</span> */}
//                                                     <div className='pr-1 flex_property' style={{ fontSize: 10, textAlign: 'right', cursor: "default", color: COLORS.gray5, height: '24px' }}>ft</div>
//                                                 </Col>
//                                                 <Col className='zero_padding flex_property' style={{ borderRight: '1px solid ' + COLORS.panel_item_borders, width: '30%' }}>
//                                                     <Input type="text" onChange={common_onchange_handler} onKeyDown={(e) => { if (e.key === 'Enter') { e.target.blur() } else if (e.key == 'Escape') { e.target.value = mmToFeetParts(json_item.default_value_dim)[1] || 0; e.target.blur() } }} defaultValue={mmToFeetParts(json_item.default_value_dim)[1] || 0} key={"key_" + json_item.id_dim + "_in"} id={json_item.id_dim + "_in"} style={{ ...styles_panel.input_styles, border: 'none', height: '24px' }} placeholder="0" />
//                                                     {/* <span style={{fontSize:13, textAlign:'left', cursor:"default"}}>in</span> */}
//                                                     <div className='pr-1 flex_property' style={{ fontSize: 10, textAlign: 'right', cursor: "default", color: COLORS.gray5, height: '24px' }}>in</div>
//                                                 </Col>
//                                                 <Col className='zero_padding flex_property' style={{ width: '30%' }}>
//                                                     <Input type="text" onChange={common_onchange_handler} onKeyDown={(e) => { if (e.key === 'Enter') { e.target.blur() } else if (e.key == 'Escape') { e.target.value = mmToFeetParts(json_item.default_value_dim)[2] || 0; e.target.blur() } }} defaultValue={mmToFeetParts(json_item.default_value_dim)[2] || 0} key={"key_" + json_item.id_dim + "_parts"} id={json_item.id_dim + "_parts"} style={{ ...styles_panel.input_styles, border: 'none', height: '24px' }} placeholder="0" />
//                                                     {/* <span style={{fontSize:13, textAlign:'left', cursor:"default"}}>{approximation_ft=='1/8_inch'?'¹/₈"':'¹/₁₆"'}</span> */}
//                                                     <div className='flex_property' style={{ fontSize: 10, textAlign: 'right', cursor: "default", color: COLORS.gray5, height: '24px' }}>{approximation_ft=='1/8_inch'?'1/8':'1/16'}</div>
//                                                 </Col>
//                                             </FocusWithin>
//                                         </Col>
//                                     </Row>
//                                 </Col>
//                             </Row>
//                         )}
//                     </Col>
//         </PanelCard>
//     )
// }

// ------------------ Generic Leaf Components End ------------------------------

// ------------------ Special Section Components Start ----------------------------

const CornerOperationsSection = ({ json_item, on_click, common_onchange_handler, is_grouped }) => {
    return (
        <PanelCard json_item={json_item}>

        </PanelCard>
    )
}

const TextPropertySection = ({ json_item, on_click, common_onchange_handler, is_grouped }) => {
    const [form_data, set_form_data] = useState({});

    useEffect(() => {
        if (json_item && json_item.submenu) {
            let json_item_submenu_copy = JSON.parse(JSON.stringify(json_item.submenu))
            let font_item = json_item_submenu_copy.find(x => x.id.endsWith("font"))
            let font_size_item = json_item_submenu_copy.find(x => x.id.endsWith("font_size"))
            let font_units_item = json_item_submenu_copy.find(x => x.id.endsWith("font_units"))
            let font_italic_item = json_item_submenu_copy.find(x => x.id.endsWith("font_italic"))
            let font_bold_item = json_item_submenu_copy.find(x => x.id.endsWith("font_bold"))
            let font_color_item = json_item_submenu_copy.find(x => x.id.endsWith("font_color"))

            if (font_item) {
                font_item.name = ''
                form_data.font = font_item
            }
            if (font_size_item) {
                font_size_item.name = ''
                form_data.font_size = font_size_item
            }
            if (font_units_item) {
                font_units_item.name = ''
                font_units_item.type = "layer_input"
                form_data.font_units = font_units_item
            }
            if (font_italic_item) {
                font_italic_item.name = 'italics'
                form_data.font_italic = font_italic_item
            }
            if (font_bold_item) {
                font_bold_item.name = 'bold'
                form_data.font_bold = font_bold_item
            }
            if (font_color_item) {
                font_color_item.name = ''
                form_data.font_color = font_color_item
            }

            set_form_data(JSON.parse(JSON.stringify(form_data)))
            // let font_item = json_item.submenu.find(x => x.endsWith("font"))
        }
    }, [json_item]);
    return (
        <PanelSectionMenu disabled={json_item.disabled}>
            {/* <div className='inf-pb-2' style={{ display: 'flex', gap: '8px' }}> */}
            {/* Select */}
            <div style={{ width: getPanelItemWidthCustom('50%', 3) }}>
                {form_data.font ? <SelectInputNew json_item={form_data.font} on_click={(args) => { on_click({ ...args, parent_id: json_item.id, json_item: form_data.font, parent_menu: json_item.submenu }) }} common_onchange_handler={common_onchange_handler} /> : ''}
            </div>

            {/* Number */}
            <div style={{ width: getPanelItemWidthCustom('25%', 3) }}>
                {form_data.font_size ? <NumericInputNew json_item={form_data.font_size} on_click={(args) => { on_click({ ...args, parent_id: json_item.id, json_item: form_data.font_size, parent_menu: json_item.submenu }) }} common_onchange_handler={common_onchange_handler} /> : ''}
            </div>

            {/* Select */}
            <div style={{ width: getPanelItemWidthCustom('25%', 3) }}>
                {form_data.font_units ? <SelectInputNew json_item={form_data.font_units} on_click={(args) => { on_click({ ...args, parent_id: json_item.id, json_item: form_data.font_units, parent_menu: json_item.submenu }) }} common_onchange_handler={common_onchange_handler} /> : ''}
            </div>
            {/* </div> */}
            {/* <div className='flex_property' style={{ gap: '8px' }}> */}
            {
                // <div style={{ display: 'flex', gap: '8px' }}>
                <>
                    <div style={{ width: 'auto' }}>
                        {form_data.font_bold ? <SelectableButton json_item={form_data.font_bold} common_onchange_handler={common_onchange_handler} on_click={(args) => { on_click({ ...args, parent_id: json_item.id, json_item: form_data.font_bold, parent_menu: json_item.submenu }) }} /> : ''}
                    </div>
                    <div style={{ width: 'auto' }}>
                        {form_data.font_italic ? <SelectableButton json_item={form_data.font_italic} common_onchange_handler={common_onchange_handler} on_click={(args) => { on_click({ ...args, parent_id: json_item.id, json_item: form_data.font_italic, parent_menu: json_item.submenu }) }} /> : ''}
                    </div>
                    {/* <SelectableButton />
                        <SelectableButton />
                        <SelectableButton /> */}
                </>
                // </div>
            }
            {/* Color Picker */}
            {/* <div style={{ flex: 1 }}> */}
            <div style={{ flex: 1 }}>
                {form_data.font_color ? <ColorPickerNew json_item={form_data.font_color} on_click={(args) => { on_click({ ...args, parent_id: json_item.id, json_item: form_data.font_color, parent_menu: json_item.submenu }) }} common_onchange_handler={common_onchange_handler} is_grouped={json_item.group_child_inputs} configuration_type={json_item.configuration_type} /> : ''}
            </div>
            {/* </div> */}
            {/* </div> */}
        </PanelSectionMenu>
    )
}

const DimensionInsetSection = ({ json_item, common_onchange_handler, on_click, metric_system, approxMM, approximation_ft, mmToFeetParts }) => {
    const [form_data, set_form_data] = useState({});

    useEffect(() => {
        if (json_item && json_item.submenu) {
            let json_item_submenu_copy = JSON.parse(JSON.stringify(json_item.submenu))

            for (let i in json_item_submenu_copy) {
                let inset = json_item_submenu_copy[i]
                form_data[`inset${i + 1}`] = inset
            }

            set_form_data(JSON.parse(JSON.stringify(form_data)))
            // let font_item = json_item.submenu.find(x => x.endsWith("font"))
        }
    }, [json_item])

    return (
        <PanelSectionMenu disabled={json_item.disabled}>
            {
                Object.keys(form_data).map((x, index) => (
                    <div style={{ width: getPanelItemWidthCustom('25%', 4) }}>
                        {form_data.font_size ? <DimensionInput json_item={form_data[`inset${index + 1}`]} on_click={(args) => { on_click({ ...args, parent_id: json_item.id, json_item: form_data[`inset${index + 1}`], parent_menu: json_item.submenu }) }} common_onchange_handler={common_onchange_handler} is_grouped={true} configuration_type={"names_above"} metric_system={metric_system} approxMM={approxMM} approximation_ft={approximation_ft} mmToFeetParts={mmToFeetParts} /> : ''}
                    </div>
                ))

            }
            {/* <div style={{ width: getPanelItemWidthCustom('25%', 4) }}>
                {form_data.font_size ? <DimensionInput json_item={form_data.inset1} on_click={(args) => { on_click({ ...args, parent_id: json_item.id, json_item: form_data.inset1, parent_menu: json_item.submenu }) }} common_onchange_handler={common_onchange_handler} is_grouped={true} configuration_type={"names_above"}  /> : ''}
            </div>
            <div style={{ width: getPanelItemWidthCustom('25%', 4) }}>
                {form_data.font_size ? <DimensionInput json_item={form_data.inset2} on_click={(args) => { on_click({ ...args, parent_id: json_item.id, json_item: form_data.inset2, parent_menu: json_item.submenu }) }} common_onchange_handler={common_onchange_handler} is_grouped={true} configuration_type={"names_above"}  /> : ''}
            </div>
            <div style={{ width: getPanelItemWidthCustom('25%', 4) }}>
                {form_data.font_size ? <DimensionInput json_item={form_data.inset3} on_click={(args) => { on_click({ ...args, parent_id: json_item.id, json_item: form_data.inset3, parent_menu: json_item.submenu }) }} common_onchange_handler={common_onchange_handler} is_grouped={true} configuration_type={"names_above"}  /> : ''}
            </div>
            <div style={{ width: getPanelItemWidthCustom('25%', 4) }}>
                {form_data.font_size ? <DimensionInput json_item={form_data.inset4} on_click={(args) => { on_click({ ...args, parent_id: json_item.id, json_item: form_data.inset4, parent_menu: json_item.submenu }) }} common_onchange_handler={common_onchange_handler} is_grouped={true} configuration_type={"names_above"}  /> : ''}
            </div> */}
        </PanelSectionMenu>
    )
}

// ------------------ Special Section Components End ------------------------------

// ------------------ Utility Components for common functionality across panel components Start ----------------------------

const PanelInput = ({ mixed, defaultValue, type, onBlur, onChange, expectedType, onKeyDown, ...props }) => {
    const [input_value, set_input_value] = useState('');
    const [prevent_submit, set_prevent_submit] = useState({ status: false });

    const handleBlur = (e) => {
        if (prevent_submit.status) {
            prevent_submit.status = false
        } else {
            if (onBlur && ((input_value != defaultValue) || mixed)) {
                let val = e.target.value;
                if (type === "number" || expectedType === "number") {
                    val = Number(e.target.value)
                }
                onBlur({ item_value: val })
            }
        }
    }
    const handleKeyDown = (e) => {
        if (onKeyDown) {
            onKeyDown(e);
        } else {
            if (e.key === 'Enter') {
                e.target.blur()
            } else if (e.key == 'Escape') {
                set_input_value(defaultValue)
                prevent_submit.status = true
                e.target.blur();
            }
        }
    }

    const handleChange = (e) => {
        set_input_value(e.target.value)
        if (onChange) {
            onChange()
        }
    }

    useEffect(() => {
        if (mixed) {
            set_input_value('')
        } else if (defaultValue != input_value) {
            set_input_value(defaultValue)
        }
    }, [defaultValue, mixed]);

    const handleFocus = (event) => {
        let input = event.target
        // input.focus()
        if (input.type === "number") {
            input.select();
        } else if (input.type === "text") {
            const inputLength = input.value.length;
            // input.setSelectionRange(inputLength, inputLength);
            input.setSelectionRange(0, inputLength);
        }
    };

    return (
        <input value={input_value} onChange={handleChange} onFocus={handleFocus} type={type} onBlur={handleBlur} onKeyDown={handleKeyDown} {...props}></input>
    )
}

const PanelItemHeader = ({ json_item, getName = (json_item) => (json_item.name), configuration_type, trim = 0 }) => {

    const getHeader = (json_item, trim) => {
        if (!trim) {
            return (getName(json_item))
        } else {
            return getName(json_item).substr(0, trim)
        }
    }

    return (
        getHeader(json_item, trim) ?
            <div className='lines1_elipsis' style={{ color: json_item.color ? json_item.color : '', minWidth: '1px', flex: configuration_type == "names_above" || configuration_type == "group_by_2" ? '1 1 100%' : !trim ? 1 : '' }} title={getName(json_item) ? getName(json_item) : ''}>{getHeader(json_item, trim)}</div>
            : ''
    )
}

const PanelCard = ({ children, style, className = "", json_item, ...props }) => {
    const disabled = json_item.disabled;
    return (
        <div data-item-type={json_item.type} className={`panel_card ${className} ${disabled ? 'panel_disabled' : ''}`} style={{ width: '100%', ...style }} {...props}>
            {children}
        </div>
    )
}

const PanelSectionMenu = ({ children, component_type, style, className = "", disabled, ...props }) => {
    return (
        <div className={`${component_type === "section" ? "panel_section_menu" : "panel_sub_section_menu"} ${className} ${disabled ? 'panel_disabled' : ''}`} style={{ width: '100%', ...style }} {...props}>
            {children}
        </div>
    )
}

// ------------------ Utility Components for common functionality across panel components End ------------------------------